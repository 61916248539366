$(document).ready(function() {

    // Prevent empty links ('#') to be clicked
    $('a').on('click', function(e) {
        var href = $(this).attr('href');
        href.substr(href.length - 1);
        if (href == '#') {
            e.preventDefault();
        }
    });

    if ($('[name="phone"]').length) {
        var SPMaskBehavior = function (val) {
            return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
        },
        spOptions = {
            onKeyPress: function(val, e, field, options) {
                field.mask(SPMaskBehavior.apply({}, arguments), options);
            }
        };

        $('[name="phone"]').mask(SPMaskBehavior, spOptions);
    }

    $('.contact-form').on('submit', function(e) {
        e.preventDefault();

        var $this = $(this);
        var $submitBtn = $this.find('[type=submit]');
        var submitBtnLabel = $submitBtn.html();

        $.ajax({
            type: $this.attr('method'),
            url: $this.attr('action'),
            data: new FormData($this[0]),
            cache: false,
            processContent: false,
            contentType: false,
            processData: false,
            dataType: false,
            beforeSend: function() {
                $submitBtn.prop('disabled', true);
                $submitBtn.html('<i class="ri ri-loader-2-line spin"></i>');
            },
            success: function(res) {
                var response = JSON.parse(res);

                new NotifyIt({
                    // Message to display.
                    'message': response[1],
                    // Status of the displayed message.
                    'status': (response[0]) ? 'success' : 'error',
                    // Duration of the alert. 0 to keep it.
                    'duration': 5
                });

                if (response[0]) {
                    $this[0].reset();
                }
            },
            error: function(jqXHR, textStatus, errorThrown) {
                new NotifyIt({
                    // Message to display.
                    'message': errorThrown,
                    // Status of the displayed message.
                    'status': 'error',
                    // Duration of the alert. 0 to keep it.
                    'duration': 5
                });
            },
            complete: function() {
                $submitBtn.prop('disabled', false);
                $submitBtn.html(submitBtnLabel);
            }
        });
    });

    tippy('[data-tippy-content]', {
        placement: 'left',
        arrow: false,
        theme: 'sharecare',
    });

    var swiperParceiros = new Swiper('.swiper-container--parceiros', {
        slidesPerView: 1,
        spaceBetween: 50,
        autoplay: {
            delay: 3000,
        },
        loop: true,
        breakpoints: {
            320: {
                slidesPerView: 2,
                spaceBetween: 20
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 3,
                spaceBetween: 30
            },
            // when window width is >= 640px
            640: {
                slidesPerView: 4,
                spaceBetween: 40
            },
            // when window width is >= 1024px
            1024: {
                slidesPerView: 5,
                spaceBetween: 50
            }
        },
    });

    var swiperRecursosThumbnail = new Swiper('.swiper-container--recursos-thumbnail', {
        simulateTouch: false,
    });

    var swiperRecursosContent = new Swiper('.swiper-container--recursos-content', {
        simulateTouch: true,
        pagination: {
            el: '.swiper-pagination--recursos',
            type: 'bullets',
            clickable: true,
        },
        breakpoints: {
            // when window width is >= 768px
            768: {
                simulateTouch: false
            },
        }
    });

    if (
        $('.swiper-container--recursos-thumbnail').length
        && $('.swiper-container--recursos-content').length
    ) {
        swiperRecursosContent.controller.control = swiperRecursosThumbnail;
    }

    $('.js-form-toggler').on('click', function(e) {
        e.preventDefault();

        var $this = $(this);
        var id = $this.prop('id');

        $('.js-form-toggler').removeClass('form-toggler--active');
        $this.addClass('form-toggler--active');
        $('.contact-form').hide();
        $('.contact-form--'+id).show();
    });

    $('.js-mobile-navbar-open-btn').on('click', function() {
        var $this = $(this);

        if ($('body').hasClass('__mobile-navbar-opened')) {
            $('body').removeClass('__mobile-navbar-opened');
            $this.html('<i class="ri ri-menu-line"></i>');
        } else {
            $('body').addClass('__mobile-navbar-opened');
            $this.html('<i class="ri ri-close-line"></i>');
        }
    });

    $('.form__input--radio[name="solicitante"]').on('change', function() {
        let $this = $(this);
        let id = $this.attr('id');
        if (id == 'responsavel-legal' || id == 'representante-legal') {
            $('.form__group--optional').addClass('form__group--show');
            $('.form__label--file.not-myself')
                .removeClass('form__label--hide')
                .addClass('form__label--show');
            $('.form__label--file.myself')
                .addClass('form__label--hide')
                .removeClass('form__label--show');
        } else {
            $('.form__group--optional').removeClass('form__group--show');
            $('.form__label--file.not-myself')
                .addClass('form__label--hide')
                .removeClass('form__label--show');
            $('.form__label--file.myself')
                .removeClass('form__label--hide')
                .addClass('form__label--show');
        }
    });

    var SPMaskBehavior = function (val) {
        return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
    },
    spOptions = {
        onKeyPress: function(val, e, field, options) {
            field.mask(SPMaskBehavior.apply({}, arguments), options);
        }
    };
    $('[name="telefone"]').each(function() { $(this).mask(SPMaskBehavior, spOptions); });
    $('[name="dataNascimento"]')
        .add('[name="dataNascimentoTitular"]').each(function() { $(this).mask('00/00/0000'); });
    $('[name="CEPResidencial"]').each(function() { $(this).mask('00000-000'); });

    $('.lgpd-form').on('submit', function(e) {
        e.preventDefault();

        var $this = $(this);
        var $submitBtn = $this.find('[type=submit]');
        var submitBtnLabel = $submitBtn.html();

        $.ajax({
            type: $this.attr('method'),
            url: $this.attr('action'),
            data: new FormData($this[0]),
            cache: false,
            processContent: false,
            contentType: false,
            processData: false,
            dataType: false,
            beforeSend: function() {
                $submitBtn.prop('disabled', true);
                $submitBtn.html('<i class="ri ri-loader-2-line spin"></i>');
            },
            success: function(res) {
                var response = JSON.parse(res);

                new NotifyIt({
                    // Message to display.
                    'message': response[1],
                    // Status of the displayed message.
                    'status': (response[0]) ? 'success' : 'error',
                    // Duration of the alert. 0 to keep it.
                    'duration': 5
                });

                if (response[0]) {
                    $this[0].reset();
                }
            },
            error: function(jqXHR, textStatus, errorThrown) {
                new NotifyIt({
                    // Message to display.
                    'message': errorThrown,
                    // Status of the displayed message.
                    'status': 'error',
                    // Duration of the alert. 0 to keep it.
                    'duration': 5
                });
            },
            complete: function() {
                $submitBtn.prop('disabled', false);
                $submitBtn.html(submitBtnLabel);
            }
        });
    });

});
